// App.js
import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Alert,
  AppBar,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import AgentChoose from "./pages/AgentChoose";
import AgentDetails from "./pages/agentDetails";
// import { agents } from "./constants/constant";
import { jwtDecode } from "jwt-decode";
import AuthContext from "./component/AuthContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import AgentPersonalisation from "./pages/AgentPersonalisation";
import axios from "axios";
import Admin from "./component/Admin";
import Checkout from "./component/Checkout";
import TierSelection from "./component/TierSelection";
import { replaceVariable } from "./utils/colorGenerator";
import PaymentStatus from "./pages/PaymentStatus";
import SubscriptionStatus from "./pages/SubscriptionStatus";
import Dataroom from "./pages/Dataroom";
import InfoDialog from "./component/InfoDialog";
import AmountDialog from "./component/AmountDialog";
// import TestAgent from "./component/TestAgent";
import logo from "./logo.png";
import AuthFailure from "./pages/AuthFailure";
const toCamelCase = (title) => {
  return title
    .split(" ")
    .map((word, index) => {
      if (index === 0) return word.toLowerCase(); // first word in lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // capitalize subsequent words
    })
    .join("");
};

const extractDescriptions = (text) => {
  const regex =
    /(?<title>Business Description|Product Description|Company FAQ):(?<value>.+)/g;
  const matches = [...text.matchAll(regex)];
  const return_value = {};

  matches.forEach((match) => {
    const title = toCamelCase(match.groups.title);
    const value = match.groups.value.trim();
    return_value[title] = value;
  });

  return return_value;
};

const App = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [agentPersonalisation, setAgentPersonalisation] = useState({});
  const [agents, setAgents] = useState([]);
  const [userData, setUserData] = useState({});
  const [backendUserData, setBackendUserData] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [agentId, setAgentId] = useState("");
  const [userId, setUserId] = useState("");
  const [chargeId, setChargeId] = useState(null);
  const [status, setStatus] = useState("pending");
  const [isPolling, setIsPolling] = useState(false);
  const [tier, SetTier] = useState(null);
  const [fetch, setfetch] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [sessionToken, setSessionToken] = useState("");
  const [activeAgent, setActiveAgent] = useState({});
  const [transferNumber, setTransferNumber] = useState("");
  const [voiceId, setVoiceId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [topupDialog, setTopupDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAgentSelect = async (val) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DASHBOARD_BACKEND}/agent/${val.id}`,
        {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            // "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      const agent = response.data;
      console.log("I am here");
      let tmp = extractDescriptions(agent.agent_prompts.task_1.system_prompt);
      let voice = agent.tasks[0]?.tools_config?.synthesizer?.provider_config
        ?.voice_id
        ? agent.tasks[0]?.tools_config?.synthesizer?.provider_config?.voice_id
        : null;
      let api_tools = agent.tasks[0]?.tools_config?.api_tools;
      let param = api_tools?.tools_params?.transfer_call?.param;
      let number = "";
      if (param) {
        try {
          let parsedParam = JSON.parse(param);
          number = parsedParam["call_transfer_number"];
        } catch (error) {
          console.error("Error parsing JSON:", error);
          number = "";
        }
      } else {
        number = "";
      }
      setTransferNumber(number);
      setVoiceId(voice);
      setSelectedAgent(agent);
      setAgentPersonalisation(tmp || {});
      navigate("/personalisation");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error fetching agent details:", error);
      }
    }
  };

  const handleAgentPersonalisation = async (value) => {
    if (Object.keys(value).length) {
      selectedAgent.agent_prompts.task_1.system_prompt =
        selectedAgent.agent_prompts.task_1.system_prompt.split(
          "Information related to Store:"
        )[0];
    }
    setAgentPersonalisation(value);
    localStorage.setItem("currentAgentID", selectedAgent.id);
    // Move to the next step
    navigate("/details");
  };

  const handleAgentData = async (value) => {
    selectedAgent.agent_name = value.agentName;
    selectedAgent.agent_prompts.task_1.system_prompt = value.prompt;
    selectedAgent.agent_welcome_message = value.welcomeMessage;
    selectedAgent.tasks[0].task_config.hangup_after_silence = value.silenceTime;
    selectedAgent.tasks[0].task_config.call_terminate = value.hangupTime;
    selectedAgent.tasks[0].tools_config.synthesizer = {
      ...selectedAgent.tasks[0].tools_config.synthesizer,
      provider: agentPersonalisation.selectedVoice.provider,
      provider_config: {
        model: agentPersonalisation.selectedVoice.model,
        similarity_boost: 0.5,
        temperature: 0.5,
        voice: agentPersonalisation.selectedVoice.name,
        voice_id: agentPersonalisation.selectedVoice.id,
      },
    };
    selectedAgent.tasks[0].tools_config.api_tools = value.transfer_call_checked
      ? {
          ...selectedAgent.tasks[0].tools_config.api_tools,
          tools: [
            {
              name: "transfer_call",
              description: "Use this tool to transfer the call",
              parameters: {
                type: "object",
                properties: {
                  call_sid: {
                    type: "string",
                    description: "unique call id",
                  },
                },
                required: ["call_sid"],
              },
              key: "transfer_call",
            },
          ],
          tools_params: {
            transfer_call: {
              method: "POST",
              param: `{"call_transfer_number":"${value.phoneNumber}","call_sid":"%(call_sid)s"}`,
              url: null,
              api_token: null,
              id: 3,
            },
          },
        }
      : null;

    const payload = {
      agent_config: {
        ...selectedAgent,
      },
      agent_prompts: {
        ...selectedAgent.agent_prompts,
      },
    };
    console.log(payload);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_DASHBOARD_BACKEND}/v2/agent/${selectedAgent.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      setAgentId(response.data.agent_id);
      setfetch(!fetch);
      if (Object.keys(activeAgent).length > 0) {
        navigate("/dataroom");
      } else {
        if (
          backendUserData?.plan_name &&
          (!activeAgent || Object.keys(activeAgent).length === 0)
        ) {
          navigate("/admin");
        } else {
          navigate("/tier-selection");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error updating agent data:", error);
      }
    }
  };

  const handleTierSelect = async (tier_value) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/payments?plan_name=${tier_value.name}`,
        { session_data: sessionToken }
      );
      if (data.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      console.log(data.data);
      SetTier(tier_value);
      setChargeId(data.data.id);
      setIsPolling(true);
      window.open(data.data.confirmation_url, "");
      if (backendUserData.plan_name) {
        navigate("/");
        alert("Kindly refresh this page after successfull payment.");
      } else {
        navigate("/admin");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error selecting tier:", error);
      }
    }
  };

  const handleTopup = async (value) => {
    console.log(value);
    const text = `Top up of $${value.amount} is done`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/top-up?text=${text}&amount=${value.amount}&charge_id=${chargeId}`,
        { session_data: sessionToken }
      );
      if (response.status == 200) {
        const minute = value.minutes;
        try {
          const response = axios.put(
            `${process.env.REACT_APP_DASHBOARD_BACKEND}/shopify/user_update?user_id=${userId}&top_up=${minute}&charge_id=${chargeId}`
          );
          if (response.status === 401) {
            alert("Kindly refresh the page");
            return;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            alert("Kindly refresh the page");
          } else {
            console.error("Error updating user:", error);
          }
        }
        alert("Payment is successful");
        window.location.reload();
      } else {
        alert("payment failed");
      }
    } catch (error) {
      console.error("Error during top-up process:", error);
      alert("Contact Founders for additional time addition");
    }

    // navigate("/admin");
  };

  const handlPhoneNumberBuy = async (value) => {
    console.log(value);
    //Handle phone Number buy page
    try {
      const agent_id = localStorage.getItem("currentAgentID");
      let provider = null;
      if (value.country !== "India") {
        provider = "twilio";
      } else {
        provider = "plivo";
      }
      const response = await axios.post(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/buy-number?phoneNumber=${value.phoneNumber}&provider=${provider}&agentId=${agent_id}&apiKey=${apiKey}&user_id=${userId}`
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      console.log(response);

      if (response.status === 200) {
        // setfetch(!fetch);
        navigate("/");
      }
      // navigate("/thank-you");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error buying phone number:", error);
      }
    }
  };

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const shop = localStorage.getItem("shopify_shop");
      let response = await axios.post(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/check_auth`,
        { access_token: accessToken, domain: shop }
        // {
        //   headers: {
        //     "ngrok-skip-browser-warning": "true",
        //     "Access-Control-Allow-Origin": "*",
        //     Authorization: `Bearer ${sid}`,
        //   },
        // }
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      //For updating the user
      try {
        const response_user_update = await axios.put(
          `${process.env.REACT_APP_DASHBOARD_BACKEND}/shopify/user_update?user_id=${response.data.agent_data.data.user_id}&access_token=${accessToken}`
        );

        console.log("User update response:", response_user_update.data);
      } catch (error) {
        console.error("Error during user update:", error.message);

        if (error.response) {
          // Request was made, but server responded with a status code outside 2xx range
          console.error("Response error:", error.response.data);
        } else if (error.request) {
          // Request was made but no response was received
          console.error("No response received from the server:", error.request);
        } else {
          // Something happened in setting up the request
          console.error("Request setup error:", error.message);
        }
      }

      await fetch_agent_mapping(response.data.agent_data.data.user_id);
      setBackendUserData(response.data.agent_data.data);
      setApiKey(response.data.agent_data.data.api_key);
      setUserId(response.data.agent_data.data.user_id);
      setAgents(response.data.agent_data.agents);
      setUserData(response.data.user_data.shop);
      setSessionToken(response.data.session_data);
      setChargeId(
        response.data.agent_data.data.charge_id
          ? response.data.agent_data.data.charge_id
          : chargeId
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/verify-charge-id?charge_id=${chargeId}`,
        { session_data: sessionToken },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      const data = response.data;
      console.log(data.status);
      setStatus(data.status);
      setfetch(!fetch);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error fetching status:", error);
      }
    }
  };

  const handleActivate = async (ele) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/remap_agent`,
        {
          agentId: ele.id,
          userId: activeAgent.user_id,
          phoneNumber: activeAgent.phone_number,
          provider: activeAgent.telephony_provider,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error activating agent:", error);
      }
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("shopify_shop");
    localStorage.removeItem("access_token");
    window.location.href = "https://www.bolna.dev/";
    return null;
  };
  console.log(process.env.REACT_APP_SHOPIFY_BACKEND_URL);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let accessToken = queryParams.get("access_token");
    let shop = queryParams.get("domain");
    if (accessToken && shop) {
      localStorage.setItem("shopify_shop", shop);
      localStorage.setItem("access_token", accessToken);
    } else {
      accessToken = localStorage.getItem("access_token");
      shop = localStorage.getItem("shopify_shop");
    }
    if (!(accessToken && shop)) {
      navigate("/auth-fail");
    }
    // localStorage.setItem("shopify_shop", shop);
    // localStorage.setItem("access_token", accessToken);
    if (accessToken) {
      try {
        setJwt(accessToken);
        // const decodedToken = jwtDecode(accessToken);
        fetchData();
        setTimeout(() => {}, 2000);
        // setJwt(accessToken);
        // sessionStorage.setItem("access_token", accessToken);
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, [fetch]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isPolling && status === "pending") {
        // Store payment status and fetch the latest status
        localStorage.setItem("payment_status", status);
        console.log(status);
        await fetchStatus();
      } else if (status === "active") {
        // Perform the user update if the charge is active
        console.log(status);
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_DASHBOARD_BACKEND}/shopify/user_update?user_id=${userId}&top_up=${tier.freeMinutes}&charge_id=${chargeId}&plan_name=${tier.name}`
          );

          if (response.status === 401) {
            alert("Kindly refresh the page");
            return;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            alert("Kindly refresh the page");
          } else {
            console.error("Error updating user:", error);
          }
        }
      }

      // Save the latest payment status in localStorage and stop polling
      localStorage.setItem("payment_status", status);

      // Clear interval when the polling condition is no longer valid
      if (status !== "pending") {
        clearInterval(interval);
      }
    }, 2000); // Adjust the interval time as needed

    return () => clearInterval(interval); // Cleanup on unmount
  }, [isPolling, status]);

  const fetch_agent_mapping = async (user_id) => {
    try {
      console.log(user_id);
      const response = await axios.get(
        `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/agent_mapping?user_id=${user_id}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status === 401) {
        alert("Kindly refresh the page");
        return;
      }
      console.log(response);
      if (response.data != null) {
        setActiveAgent(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Kindly refresh the page");
      } else {
        console.error("Error fetching agent mapping:", error);
      }
    }
  };

  return (
    <>
      <AppBar
        position="static"
        color="primary"
        elevation={4}
        sx={{
          backgroundColor: "#1976d2",
          flexDirection: "row",
        }}
      >
        <div style={{ margin: "10px" }}>
          <a href="/">
            <img
              alt="Logo"
              src={logo}
              width={"100"}
              style={{ borderRadius: "5px" }}
            ></img>
          </a>
        </div>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "95%",
            marginRight: "16px",
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            disabled={!backendUserData.plan_name}
            onClick={() => navigate("/tier-selection")}
            sx={{ marginRight: "16px" }}
          >
            Upgrade Tier
          </Button>

          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setTopupDialog(true)}
            disabled={!chargeId}
            sx={{ marginRight: "16px" }}
          >
            Top Up
          </Button>

          <Button
            onClick={handleDialogOpen}
            variant="outlined"
            color="inherit"
            sx={{ marginRight: "16px" }}
          >
            Contact Founders
          </Button>

          <Button
            onClick={handleLogout}
            variant="outlined"
            color="inherit"
            sx={{ marginRight: "16px" }}
            disabled={!sessionToken.accessToken ? true : false}
          >
            Log out
          </Button>

          <Typography
            variant="caption"
            color="inherit"
            sx={{
              // color: "text.secondary",
              fontSize: "1.1rem",
              fontWeight: "bold",
              marginRight: "16px",
            }}
          >
            Minutes left: {backendUserData.total_minutes || 0}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <AmountDialog
          openDialog={topupDialog}
          handleClose={() => {
            setTopupDialog(!topupDialog);
          }}
          onSubmit={handleTopup}
          plan_name={backendUserData.plan_name}
        />
        <InfoDialog open={dialogOpen} onClose={handleDialogClose} />
        <Routes>
          <Route
            path="/"
            element={
              <AgentChoose
                activeAgent={activeAgent}
                agentData={agents}
                onAgentSelect={handleAgentSelect}
                onActivate={handleActivate}
              />
            }
          />
          <Route
            path="/personalisation"
            element={
              selectedAgent ? (
                <AgentPersonalisation
                  data={agentPersonalisation}
                  apiKey={apiKey}
                  text={replaceVariable(
                    selectedAgent.agent_welcome_message,
                    userData.name,
                    selectedAgent.agent_name
                  )}
                  voiceId={voiceId}
                  onSubmit={handleAgentPersonalisation}
                  onPrev={() => navigate("/")} // Back navigation
                />
              ) : (
                <Navigate to="/" /> // Redirect if no agent is selected
              )
            }
          />
          <Route
            path="/details"
            element={
              selectedAgent ? (
                <AgentDetails
                  apiKey={apiKey}
                  agent={selectedAgent}
                  user_data={userData}
                  personalisationData={agentPersonalisation}
                  onNext={handleAgentData}
                  onBack={() => navigate("/personalisation")} // Back navigation
                  valueHangup={
                    selectedAgent.tasks[0].task_config.call_terminate
                  }
                  valueSilence={
                    selectedAgent.tasks[0].task_config.hangup_after_silence
                  }
                  number={transferNumber}
                />
              ) : (
                <Navigate to="/" /> // Redirect if no agent is selected
              )
            }
          />
          <Route
            path="/tier-selection"
            element={
              <TierSelection
                userId={userId}
                agentId={agentId}
                apiKey={apiKey}
                user_data={backendUserData}
                onSelect={handleTierSelect}
                onBack={() => navigate("/details")} // Back navigation
              />
            }
          />
          <Route
            path="/subscription-not-found"
            element={<SubscriptionStatus />}
          />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route
            path="/admin"
            element={
              <Admin
                auth_token={jwt}
                onSubmit={handlPhoneNumberBuy}
                onBack={() => navigate("/tier-selection")}
                chargeId={chargeId}
                status={status}
                userId={userId}
                userData={backendUserData}
              />
            }
          />
          <Route
            path="/dataroom"
            element={
              <Dataroom
                apiKey={apiKey}
                userId={userId}
                agents={agents}
                phoneNumber={activeAgent.phone_number}
                activeAgent={activeAgent}
              />
            }
          />
          <Route path="/auth-fail" element={<AuthFailure />} />
        </Routes>
      </Container>
    </>
  );
};

export default App;
