import React, { useState } from "react";
import { Box, Slider, TextField, Typography } from "@mui/material";

const CustomSlider = ({ onChange, text, val, max, min }) => {
  const [value, setValue] = useState(val); // Initial value

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    onChange(newValue);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const newValue = inputValue === "" ? "" : Number(inputValue);

    const finalValue = newValue > max ? max : newValue;
    setValue(finalValue);
    onChange(finalValue);
  };

  const handleBlur = () => {
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "center",
        padding: 2,
        border: 1,
        borderColor: "lightgray",
        borderRadius: 1,
      }}
    >
      <Typography id="input-slider" style={{ marginTop: "15px" }}>
        {text}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", width: "70%" }}>
        <Slider
          value={typeof value === "number" ? value : 0}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          min={min}
          max={max}
          sx={{
            flex: 1,
            "& .MuiSlider-thumb": {
              backgroundColor: "white",
            },
            "& .MuiSlider-track": {
              backgroundColor: "#1cbad1",
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#b0f5ff",
            },
          }}
        />
        <TextField
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: min,
            max: max,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          sx={{ width: 80, marginLeft: 2 }}
        />
      </Box>
    </Box>
  );
};

export default CustomSlider;
