// InfoDialog.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const InfoDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Contact Founders</DialogTitle>
      <DialogContent>
        <Typography>
          Send mail at{" "}
          <a href="mailto:maitreya@bolna.dev">maitreya@bolna.dev</a> or set up a
          meeting here -{" "}
          <a
            href="https://calendly.com/founder-bolna/bolna-onboarding"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://calendly.com/founder-bolna/bolna-onboarding
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
