import React, { useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';

const PaymentStatus = ({ onStatusUpdate, jwt }) => {
  useEffect(() => {
    // Set a timer to close the tab after 3 seconds
    const timer = setTimeout(() => {
      window.close();
    }, 3000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      <Box textAlign="center" mt={5}>
        <>
          <Typography variant="h4" color="green">
            Payment Successful!
          </Typography>
          <Typography variant="h6" mt={2}>
            Thank you for your purchase. Your transaction was completed successfully.
          </Typography>
        </>
      </Box>
    </Container>
  );
};

export default PaymentStatus;
