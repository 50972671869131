import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Papa from "papaparse";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TestAgent from "../component/TestAgent";

const Dataroom = ({ apiKey, userId, agents, phoneNumber, activeAgent }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedAgent, setSelectedAgent] = useState(""); // State for selected agent
  const navigate = useNavigate();

  function convertSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes} min ${remainingSeconds} sec`;
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const fromDate = new Date(String(startDate)).toISOString();
      const toDate = new Date(String(endDate)).toISOString();
      console.log(fromDate);
      console.log(toDate);
      let query = `start_date=${fromDate}&end_date=${toDate}`;
      const response = await axios.get(
        `${process.env.REACT_APP_DASHBOARD_BACKEND}/admin/user_data?${query}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const filtered = response.data.result.filter(
        (item) => item.user_id === userId
      );

      const resultantArray = filtered.map((obj) => {
        const agent = agents.find((agent) => agent.id === obj.agent_id);
        if (agent) {
          return {
            ...obj,
            agent_name: agent.agent_name,
          };
        }
        return obj;
      });

      setData(resultantArray); // Store the raw data
      setFilteredData(resultantArray); // Initially set filtered data to the raw data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "DataRoom_Report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleApplyFilters = () => {
    if (startDate && endDate) {
      fetchData();
    } else {
      alert("Please select both start and end dates");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
    if (event.target.value === "") {
      setFilteredData(data); // If no agent is selected, show all data
    } else {
      setFilteredData(
        data.filter((item) => item.agent_name === event.target.value)
      );
    }
  };
  useEffect(() => {
    const today = new Date();
    const twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(today.getDate() - 2);

    setEndDate(today);
    setStartDate(twoDaysAgo);
    fetchData();
  }, []);
  return (
    <Container>
      <h1>Data Room</h1>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={
              <TextField
                label="Start Date"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            }
            dateFormat="yyyy/MM/dd"
          />
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            customInput={
              <TextField
                label="End Date"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            }
            dateFormat="yyyy/MM/dd"
          />
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel id="agent-select-label">Agent</InputLabel>
          <Select
            labelId="agent-select-label"
            id="agent-select"
            value={selectedAgent}
            onChange={handleAgentChange}
            label="Agent"
          >
            <MenuItem value="">
              <em>All Agents</em>
            </MenuItem>
            {agents.map((agent) => (
              <MenuItem key={agent.id} value={agent.agent_name}>
                {agent.agent_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {phoneNumber ? (
          <>
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={phoneNumber}
              />
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </div>{" "}
      {/* <div style={{ marginBottom: "10px", textDecoration: "bold" }}></div> */}
      <div style={{ marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDownloadCSV}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          Download CSV
        </Button>
        {/* <div style={{marginLeft:"5px"}}> */}
        <TestAgent apiKey={apiKey} agentId={activeAgent.agent_id} />
        {/* </div> */}
      </div>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ marginTop: "20px" }}>
        <h2>Data</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Agent Name</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Conversation Time</TableCell>
                <TableCell>Summary</TableCell>
                <TableCell>Recording Url</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow key={item.user_id}>
                      <TableCell>{item.agent_name}</TableCell>
                      <TableCell>{item.created_at}</TableCell>
                      <TableCell>
                        {convertSeconds(item.conversation_time)}
                      </TableCell>
                      <TableCell>{item.summary}</TableCell>
                      <TableCell>
                        <div>
                          <audio controls src={item.recording_url}>
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        Go Home
      </Button>
    </Container>
  );
};

export default Dataroom;
