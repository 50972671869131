// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {

//     const checkAuthStatus = async () => {
//       try {
//         const response = await fetch('https://2187-2405-201-4033-4011-d888-c684-c2d6-5a3e.ngrok-free.app/api/check-auth',{
//             headers: {
//                 'ngrok-skip-browser-warning': 'true',
//               },
//         }); // Replace with your API endpoint
//         const data = await response.json();
//         console.log(data)
//         setIsAuthenticated(data.isAuthenticated);
//       } catch (error) {
//         setIsAuthenticated(false);
//       }
//     };
//     console.log("I am here")
//     checkAuthStatus();
//   }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
