// src/pages/Checkout.js
import React from 'react';
import { Button, Typography, Container, Card, CardContent, Divider } from '@mui/material';

const Checkout = ({ tier, onPay }) => {
  if (!tier) return null;

  const calculateAddOnCost = (minutes, addOnRate) => {
    if (addOnRate === '10c per minute') {
      return (minutes * 0.10).toFixed(2);
    } else if (addOnRate === '8c per minute') {
      return (minutes * 0.08).toFixed(2);
    }
    return '0.00';
  };

  const addOnCost = tier.addOnRate ? calculateAddOnCost(tier.freeMinutes, tier.addOnRate) : '0.00';

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Checkout
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="h5">{tier.name} Plan</Typography>
          <Divider />
          <Typography variant="h6">Price: ${tier.price}</Typography>
          <Typography variant="body1">Phone Numbers: {tier.phoneNumbers}</Typography>
          <Typography variant="body1">Free Inbound Minutes: {tier.freeMinutes}</Typography>
          {tier.addOnRate && (
            <Typography variant="body1">
              Add-On Cost: ${addOnCost} ({tier.addOnRate})
            </Typography>
          )}
          <Divider />
          <Typography variant="h6">Total Cost: ${tier.price}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onPay}
            style={{ marginTop: '16px' }}
          >
            Pay
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Checkout;
