import React from "react";
import { Button, Typography, Container } from "@mui/material";

const AuthFailure = () => {
  const handleRedirect = () => {
    window.location.href = "https://www.bolna.dev"; // Redirect to bolna.dev
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        Authentication Failed
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "40px" }}>
        Authentication failed, go back to the app page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRedirect}
        sx={{
          padding: "10px 20px",
          fontSize: "16px",
          fontWeight: "600",
          borderRadius: "5px",
        }}
      >
        Go to the website
      </Button>
    </Container>
  );
};

export default AuthFailure;
