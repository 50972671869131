import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import axios from "axios";

// Define a mapping from accent to flag emoji
const flagEmojis = {
  "United States (English)": "🇺🇸",
  indian: "🇮🇳",
  american: "🇺🇸",
  british: "🇬🇧",
};

const VoiceCard = ({
  name,
  accent,
  image,
  isSelected,
  onSelect,
  provider,
  model,
  id,
  text,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const flagEmoji = flagEmojis[accent] || "🏳️";

  // Play audio and select the voice
  const handlePlay = async (event) => {
    event.stopPropagation();

    // Select the voice when the play button is clicked
    onSelect(); // Call onSelect to update the selected voice

    const generate = {
      text: text,
      provider: provider,
      provider_config: { model: model, voice: name, voice_id: id },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_DASHBOARD_BACKEND}/tts/generate`,
      generate,
      {
        headers: {
          Authorization: `Bearer bn-68507b9f0dd34a17b4eff17d16712ddc`,
        },
      }
    );

    const audioUrl = `data:audio/wav;base64,${response.data.data}`;
    const audio = new Audio(audioUrl);
    audio.play();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "10px",
      }}
      onClick={onSelect} // Call onSelect when the card is clicked
    >
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handlePlay} // Handle play button click
        sx={{
          position: "relative",
          width: 80,
          height: 80,
          border: isSelected ? "3px solid blue" : "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5px",
          borderRadius: "50%",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          cursor: "pointer",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 1,
          },
        }}
      >
        {isHovered && (
          <IconButton
            sx={{
              height: "30%",
              width: "30%",
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <PlayArrowIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Typography variant="body2" sx={{ color: "black", textAlign: "center" }}>
        {name} {flagEmoji}
      </Typography>
    </div>
  );
};

export default VoiceCard;
