// src/pages/Admin.js
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SubscriptionStatus from "../pages/SubscriptionStatus";

const countries = ["USA", "Canada", "UK"];
const phoneCodes = ["+1", "+44", "+33"];

const Admin = ({ onSubmit, auth_token, chargeId, status, userData }) => {
  const [country, setCountry] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [twilioData, setTwilioData] = useState([]);

  const prioritizeCountries = (array, priorityCountries) => {
    return array.sort((a, b) => {
      const aIndex = priorityCountries.indexOf(a.country);
      const bIndex = priorityCountries.indexOf(b.country);

      if (aIndex === -1 && bIndex === -1) {
        return 0;
      }
      if (aIndex === -1) {
        return 1;
      }
      if (bIndex === -1) {
        return -1;
      }
      return aIndex - bIndex;
    });
  };

  const fetchData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SHOPIFY_BACKEND_URL}/list-countries`
      // ,
      // {
      //   headers: {
      //     "ngrok-skip-browser-warning": "true",
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // }
    );
    let countries = ["United Kingdom", "United States", "Canada", "India"];
    let country_list = prioritizeCountries(response.data, countries);
    setTwilioData(country_list);
    console.log(response.data);
  };
  const handleCountryChange = (e) => {
    const selectedCountry = twilioData.find(
      (c) => c.countryCode === e.target.value
    );
    setCountry(selectedCountry);
    setPhoneNumber("");
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log(country);
  }, [country]);

  return (
    <>
      {status === "active" || userData.plan_name ? (
        <Container>
          <Typography variant="h4" gutterBottom>
            Buy Number
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Country</InputLabel>
            <Select
              label="Country"
              value={country.countryCode || ""}
              onChange={handleCountryChange}
            >
              {twilioData.map((c) => (
                <MenuItem key={c.countryCode} value={c.countryCode}>
                  {c.country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Phone Number</InputLabel>
            <Select
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={
                !country.phone_numbers || country.phone_numbers.length === 0
              }
            >
              {country.phone_numbers &&
                country.phone_numbers.map((phone) => (
                  <MenuItem
                    key={phone.phoneNumber}
                    value={phone.phoneNumber || phone.number}
                  >
                    {phone.friendlyName || phone.number}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit({ phoneNumber, country: country.country })}
            disabled={!phoneNumber}
          >
            Submit
          </Button>
        </Container>
      ) : (
        <SubscriptionStatus />
      )}
    </>
  );
};

export default Admin;
