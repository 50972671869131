import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubscriptionStatus = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    // Navigate to the desired endpoint
    navigate('/tier-selection'); // Change '/subscribe' to your desired route
  };

  return (
    <Container>
      <Box textAlign="center" mt={5}>
        <Typography variant="h4" color="red">
          Your Subscription is Not Active
        </Typography>
        <Typography variant="h6" mt={2}>
          Please activate your subscription to access premium features.
        </Typography>
        <Button variant="contained" color="primary" mt={3} onClick={handleNavigate}>
          Go to tier Page
        </Button>
      </Box>
    </Container>
  );
};

export default SubscriptionStatus;
