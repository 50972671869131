import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  InputLabel,
} from "@mui/material";
import axios from "axios";

const TestAgent = ({ agentId, apiKey }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [testNumber, setTestNumber] = useState("");

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleTestNumberChange = (event) => {
    setTestNumber(event.target.value);
  };

  const handleDialogSubmit = async () => {
    const body = {
      agent_id: agentId,
      recipient_phone_number: testNumber,
      from_phone_number: "",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_DASHBOARD_BACKEND}/call`,
      body,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    console.log(response.data);
    console.log(body)
    console.log(`Test number entered: ${testNumber}`);
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        size="medium"
        sx={{
        //   padding: "10px 20px",
        //   fontSize: "16px",
        //   fontWeight: "600",
          borderRadius: "4px",  // Changed to a rectangle by reducing border radius
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#2196F3", // Solid color
          "&:hover": {
            backgroundColor: "#1976D2",  // Solid hover color
          },
        }}
      >
        Test Agent
      </Button>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "22px" }}
        >
          Enter Test Agent Number
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="caption"
            gutterBottom
            align="center"
            sx={{ marginBottom: "40px", display: "block" }}
          >
            Enter phone numbers with country code (for example: +16507638870)
          </Typography>
          <InputLabel
            sx={{ fontWeight: "bold", marginBottom: "10px", fontSize: "15px" }}
          >
            Phone Number
          </InputLabel>
          <TextField
            sx={{
              marginTop: "10px",
              marginBottom: "20px",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            autoFocus
            margin="dense"
            label="Mobile Number"
            type="text"
            fullWidth
            value={testNumber}
            onChange={handleTestNumberChange}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 20px",
              borderRadius: "4px",  // Rectangular shape for the Cancel button
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDialogSubmit}
            color="primary"
            variant="contained"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 20px",
              borderRadius: "4px",  // Rectangular shape for the Submit button
              backgroundColor: "#2196F3",  // Solid color
              "&:hover": {
                backgroundColor: "#1976D2",  // Solid hover color
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TestAgent;
