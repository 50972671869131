import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  Switch,
} from "@mui/material";
import { replaceVariable } from "../utils/colorGenerator";
import CustomSlider from "../component/CustomSlider";

const AgentDetails = forwardRef(
  (
    {
      agent,
      onNext,
      onBack,
      agentData,
      personalisationData,
      user_data,
      valueHangup,
      valueSilence,
      number,
    },
    ref
  ) => {
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [prompt, setPrompt] = useState("");
    const [agentName, setAgentName] = useState("");
    const [checked, setChecked] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [hangupTime, setHangUpTime] = useState(valueHangup);
    const [silenceTime, setSilenceTime] = useState(valueSilence);
    const handleToggleChange = (event) => {
      setChecked(event.target.checked);
    };
    const handlePhoneNumberChange = (event) => {
      setPhoneNumber(event.target.value);
    };
    console.log(`user data value ${user_data}`);
    console.log(hangupTime);
    console.log(silenceTime);
    // const replaceVariable = (text)=>{
    //   const variables = {
    //     business_name: user_data.name,
    //     agent_name: agent.agent_name,
    //     about_business: "bussiness description",
    //     FAQ: "Company FAQ",
    //     about_product: "Product Description"
    //   };
    //   const message = text.replace(/{(\w+)}/g, (match, p1) => variables[p1] || match);
    //   console.log(message)
    //   return message
    // }

    useEffect(() => {
      if (agent) {
        if (number != "" && number) {
          setPhoneNumber(number);
          setChecked(true);
        }

        setAgentName(agent.agent_name || "");
        setWelcomeMessage(
          () =>
            replaceVariable(
              agent.agent_welcome_message,
              user_data.name,
              agent.agent_name
            ) || ""
        );
        setPrompt(() =>
          replaceVariable(
            agent.agent_prompts.task_1.system_prompt +
              "\nInformation related to Store:" +
              `\n\nBusiness Description:${
                personalisationData.businessDescription || ""
              }\n\nProduct Description:${
                personalisationData.productsDescription || ""
              }\n\nCompany FAQ: ${personalisationData.companyFaqs || ""}` || "",
            user_data.name,
            agent.agent_name
          )
        );
        setHangUpTime(valueHangup);
        setSilenceTime(valueSilence);
      }
    }, [agent, agent]);

    const handleSubmit = () => {
      onNext({
        welcomeMessage,
        prompt,
        agentName,
        phoneNumber: phoneNumber,
        transfer_call_checked: checked,
        hangupTime: hangupTime,
        silenceTime: silenceTime,
      });
    };
    useEffect(() => {
      console.log(hangupTime);
    }, [hangupTime]);

    return (
      <Container ref={ref}>
        <Box
          sx={{
            backgroundColor: "white",
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
            maxWidth: 700,
            margin: "auto",
            mt: 1,
            mb: 1,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Agent Details
          </Typography>

          <Grid container>
            <Grid item xs={50} md={150}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField
                  label="Agent Name"
                  variant="outlined"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                  fullWidth
                  // InputProps={{ readOnly: true }}
                />

                <TextField
                  label="Welcome Message"
                  variant="outlined"
                  value={welcomeMessage}
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                  fullWidth
                />

                <TextField
                  label="Prompt"
                  variant="outlined"
                  multiline
                  rows={20}
                  value={replaceVariable(prompt)}
                  onChange={(e) => setPrompt(e.target.value)}
                  fullWidth
                />
                <CustomSlider
                  onChange={(value) => setSilenceTime(value)}
                  text={"Hangup on Silence(in sec)"}
                  max={30}
                  min={1}
                  val={silenceTime}
                />
                <CustomSlider
                  onChange={(value) => setHangUpTime(value)}
                  text={"Maximum Call Duration(in sec)"}
                  max={600}
                  min={30}
                  val={hangupTime}
                />

                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 2,
                      border: 1,
                      borderColor: "lightgray",
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography variant="subtitle1">Transfer call</Typography>

                      <Typography variant="body2" color="textSecondary">
                        Enter the mobile number to which you want the call to be
                        transferred. Please include the country code (for
                        example: +16507638870).
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "50%",
                      }}
                    >
                      <Switch
                        checked={checked}
                        onChange={handleToggleChange}
                        inputProps={{ "aria-labelledby": "extraction-toggle" }}
                      />

                      <TextField
                        id="phone-number"
                        label="Phone Number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        disabled={!checked}
                        sx={{ width: 200 }}
                      />
                    </Box>
                  </Box>
                </>
              </Box>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onBack}
            sx={{ mt: 2, maxWidth: "20px", mr: 2 }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2, maxWidth: "20px" }}
          >
            Next
          </Button>
        </Box>
      </Container>
    );
  }
);

export default AgentDetails;
