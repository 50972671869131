export const getRandomColor = () => {
  const hue = Math.floor(Math.random() * 50);
  const saturation = "80%";
  // Lightness is set between 40% and 60% to avoid too light or too dark colors
  const lightness = `${Math.floor(Math.random() * 20) + 40}%`;

  return `hsl(${hue}, ${saturation}, ${lightness})`;
};

export const replaceVariable = (text,bussiness_name,agent_name)=>{
  const variables = {
    business_name: bussiness_name,
    agent_name: agent_name,
    about_business: "bussiness description",
    FAQ: "Company FAQ",
    about_product: "Product Description"
  };
  const message = text.replace(/{(\w+)}/g, (match, p1) => variables[p1] || match);
  console.log(message)
  return message
}
